<template>
  <div class="church-presentation-template">
    <page-body class>
      <phone-form
        v-if="translations.components"
        :individualName="this.individualProfile.formal_name"
        :individualKey="this.individualProfile.ind_key"
        :userKey="this.userIndKey"
        :phone="this.userSelectedPhone"
        :i18n="translations.components"
      ></phone-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import phoneForm from '@/components/PhoneForm.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-individual-phone',
  mixins: [translationMixin],
  data() {
    return { translations: {} }
  },
  computed: {
    ...mapGetters({
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userSelectedPhone: 'user/userSelectedPhone',
    }),
  },
  async created() {
    await Promise.all([
      this.getComponentTranslations('phone-form'),
      this.getComponentTranslations('common.fax-types', 'common.phone-types'),
    ]).then((results) => {
      this.stripReadableText(results[1])
      const translatedText = {
        ...results[0],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
    })
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    phoneForm: phoneForm,
  },
}
</script>

<style scoped>
.page-body {
  padding: 80px 15px;
}
</style>
